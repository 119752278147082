import React from "react"
import styled from "styled-components"

import heroImage from "../assets/img/ourCharityHero/we-are-a-charity.png"
import heroImage2x from "../assets/img/ourCharityHero/we-are-a-charity@2x.png"
import { LAYOUT } from "../constants"

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  cssEase: "linear",
  arrows: false,
  pauseOnHover: false,
}

const Root = styled.div``

const RfhImage = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${heroImage});
  width: calc(100% - 40px);
  align-self: center;
  background-position: center;
  height: 200px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    height: 450px;
    margin-bottom: 0;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url(${heroImage2x});
  }
`

const HeroContainer = styled.div`
  max-width: 906px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    padding-top: 0;
    flex-direction: unset;
    flex-direction: column;
    align-items: center;
  }
`

interface IOurCharityHero {
  rest?: object
}

const OurCharityHero: React.FC<IOurCharityHero> = ({ ...rest }) => {
  return (
    <Root {...rest}>
      <HeroContainer>
        <RfhImage />
      </HeroContainer>
    </Root>
  )
}

export default OurCharityHero
