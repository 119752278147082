import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import BodyText from "../components/BodyText"
import { H2 } from "../components/Heading"
import { LAYOUT, FONT_FAMILIES } from "../constants"

const Root = styled.div`
  max-width: 750px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
`

const StyledBodyText = styled(BodyText)`
  margin-bottom: 40px;
`

const StyledHeading = styled(H2)`
  margin-bottom: 10px;
`

interface IFundraiseForUs {
  rest?: object
}

const FundraiseForUs: React.FC<IFundraiseForUs> = ({ ...rest }) => {
  const imgData = useStaticQuery(graphql`
    query FundraiseForUsQuery {
      fundRaisers: file(
        relativePath: { eq: "fundraiseForUs/fund-raisers.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root {...rest}>
      <StyledHeading color={"WHITE"} alignment={"CENTER"}>
        Would you like to fundraise for us?
      </StyledHeading>
      <StyledBodyText type={"LIGHT"} alignment={"CENTER"}>
        <p>
          <Link to={"/contact-us"}>Get in touch</Link> and tell us what you have
          planned!
        </p>
      </StyledBodyText>
      <Img
        fluid={imgData.fundRaisers.childImageSharp.fluid}
        alt={"Mindful brain"}
      />
    </Root>
  )
}

export default FundraiseForUs
