import React from "react"
import styled from "styled-components"

import BodyText from "../components/BodyText"
import { LAYOUT } from "../constants"

const Root = styled.div`
  max-width: 750px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
`

const StyledBodyText = styled(BodyText)`
  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    p,
    strong,
    a {
      font-size: 22px;
    }
  }
`

interface ITextBlock {
  rest?: object
}

const TextBlock: React.FC<ITextBlock> = ({ children, ...rest }) => {
  return (
    <Root {...rest}>
      <StyledBodyText type={"DARK"} alignment={"CENTER"}>
        {children}
      </StyledBodyText>
    </Root>
  )
}

export default TextBlock
