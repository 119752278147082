import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

import Nav from "../../partials/Nav"
import PageSection from "../../layout/PageSection"
import OurCharityHero from "../../partials/OurCharityHero"
import TextBlock from "../../partials/TextBlock"
import FundraiseForUs from "../../partials/FundraiseForUs"
import OurInitiatives from "../../partials/OurInitiatives"
import Footer from "../../partials/Footer"
import CookieBanner from "../../partials/CookieBanner"

import "../../styles.css"

const OurCharityPage: React.FC<{}> = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Charity | Fundraise for us | Run For Heroes</title>
        <meta
          name="description"
          content="Looking to fundraise for us? Find out about how you can support and the health and wellbeing initiatives we fund."
        />
        <html lang="en" />
      </Helmet>
      <Nav activePath={"/about-us/our-charity"} />
      <PageSection backgroundColor={"BLUE"}>
        <OurCharityHero />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <TextBlock>
          <p>
            On 16th February 2021, Run For Heroes became an incorporated
            charity. Our charitable mission is to promote health and wellbeing
            by increasing public participation in sport, while raising funds for
            health and wellbeing initiatives throughout the UK.
          </p>
        </TextBlock>
      </PageSection>
      <PageSection backgroundColor={"PINK"}>
        <OurInitiatives />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <TextBlock>
          <p>
            If you would like funding for a project{" "}
            <a href={"mailto:funding@runforheroes.uk"}>
              please contact us here
            </a>
            . Applications will be reviewed every 6 months, the next review will
            take place in <strong>October 2021</strong>.
          </p>
        </TextBlock>
      </PageSection>
      <PageSection backgroundColor={"GREEN"}>
        <FundraiseForUs />
      </PageSection>
      <PageSection backgroundColor={"GREY"}>
        <Footer />
      </PageSection>
      <CookieBanner />
    </>
  )
}

export default OurCharityPage
