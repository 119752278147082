import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import BodyText from "../components/BodyText"
import { H2, H3 } from "../components/Heading"
import { LAYOUT } from "../constants"

const VALUES_DATA = [
  {
    heading: (
      <>
        Mental
        <br /> wellbeing
      </>
    ),
    key: "bodySoul",
    description: <>Initiatives that support mental health and wellbeing.</>,
  },
  {
    heading: <>Youth and disability</>,
    key: "youthDisability",
    description: (
      <>Programmes promoting greater community inclusion within sport.</>
    ),
  },
  {
    heading: (
      <>
        Key
        <br /> workers
      </>
    ),
    key: "keyWorkers",
    description: <>Support for key workers welfare and wellbeing.</>,
  },
  {
    heading: (
      <>
        Sport
        <br /> Initiatives
      </>
    ),
    key: "sportIntiatives",
    description: (
      <>
        Initiatives that actively encourage more public participation in sport.
      </>
    ),
  },
]

const Root = styled.div`
  max-width: 1340px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
`

const Heading = styled(H2)`
  max-width: 714px;
  margin: 0 auto 40px auto;
`

const ValuesContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const ValueBox = styled.div`
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    max-width: 280px;
    margin-left: 15px;
    margin-right: 15px;
  }
  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    max-width: 190px;
  }
`

const StatBoxHeading = styled(H3)`
  font-size: 2.4rem;
  margin-bottom: 10px;
`

interface IValueBoxImage {
  fluid: object
}
const ValueBoxImage = styled(Img)<IValueBoxImage>`
  max-width: 172px;
  align-self: center;
  justify-self: center;
  margin: 0 auto 15px auto;
`

interface IOurInitiatives {
  rest?: object
}

const OurInitiatives: React.FC<IOurInitiatives> = ({ ...rest }) => {
  const imgData = useStaticQuery(graphql`
    query OurInitiativesQuery {
      bodySoul: file(relativePath: { eq: "ourInitiatives/body-and-soul.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      youthDisability: file(
        relativePath: { eq: "ourInitiatives/youth-and-disablility.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      keyWorkers: file(relativePath: { eq: "ourInitiatives/key-workers.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      sportIntiatives: file(
        relativePath: { eq: "ourInitiatives/sport-initiatives.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root {...rest}>
      <Heading alignment={"CENTER"} color={"WHITE"}>
        <H2 color={"WHITE"}>
          We are proud to provide funding and support to organisations aligned
          with our purpose
        </H2>
      </Heading>
      <ValuesContainer>
        {VALUES_DATA.map((data, i) => (
          <ValueBox key={i}>
            <ValueBoxImage
              loading={"eager"}
              fadeIn={false}
              fluid={imgData[data.key].childImageSharp.fluid}
            />
            <StatBoxHeading alignment={"CENTER"} color={"WHITE"}>
              {data.heading}
            </StatBoxHeading>
            <BodyText alignment={"CENTER"}>
              <p>{data.description}</p>
            </BodyText>
          </ValueBox>
        ))}
      </ValuesContainer>
    </Root>
  )
}

export default OurInitiatives
